const addresses = {
  LBTC_Eth: "0xc1Dd1e835F4cdFF9fc12Cb1750428E345A3d3d5f",
  LBTC_Lux: "0x2d6Bce32Ea63F2e2CDfCAd49999e89a00389Df4C",
  WSHM_Eth: "0xc1Dd1e835F4cdFF9fc12Cb1750428E345A3d3d5f",
  WSHM_Bsc: "0x3E499B1ecda1a5d3B62DCAd13514779d7a381914",
  WSHM_Shm: "0x2d6Bce32Ea63F2e2CDfCAd49999e89a00389Df4C",//"0x90Ab6e4F5f5De2d47B15274b55e4D0D4A14D5F55",//
  Token_Eth: "0xc1Dd1e835F4cdFF9fc12Cb1750428E345A3d3d5f",
  Token_Shm: "0x2d6Bce32Ea63F2e2CDfCAd49999e89a00389Df4C",
  Token_Bsc: "0x3E499B1ecda1a5d3B62DCAd13514779d7a381914",
  Teleport_Eth: "0x6c429af2EEd84ae2973D9B2CeA6E07e0b20A34C7",
  Teleport_Lux: "0x2a91f82d37b09f75531e11D81a923d7E66f33B02",//"0xf0FDAB2C7bc42Cf3EB860053cc0dbd77826aF9b4",//
  Teleport_Shm: "0x2a91f82d37b09f75531e11D81a923d7E66f33B02",//"0xf0FDAB2C7bc42Cf3EB860053cc0dbd77826aF9b4",//
  Teleport_Bsc: "0x52D6Cadee0A2C273C97F90bF09AE8E7f06CE4Db7",
  TeleportContractVault: "0xA211B536C429CCA275BdcC338b0492b251bDBEC0", //"0x22B8973acd02633BB47a8C7C381463b5b9d02879"
};

export default addresses;
