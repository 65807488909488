import React, { useState } from "react";
import Cookies from 'universal-cookie';
import { Contract } from "@ethersproject/contracts";
import { ethers } from "ethers";
import detectEthereumProvider from '@metamask/detect-provider';
import { useLocation } from "react-router-dom";
//import useWeb3Modal from "../hooks/useWeb3Modal";
import MetaMaskOnboarding from '@metamask/onboarding';
import axios from 'axios';

import addresses from "./abi/addresses";
import abis from "./abi/abis";
import { Grid, Form, Box, Card, Text, CardBody, Spinner, TextInput, FormField, Heading, ResponsiveContext, RadioButtonGroup, Grommet, Menu } from "grommet";
import { ButtonForm, StyledButton, HeadingDark, StyledTextDark, customBreakpoints } from ".";
//const rpcURL = "https://liberty20.shardeum.org/"
import Web3 from "web3";//const web3 = new Web3(rpcURL)

import ethLogo from "../img/eth.svg";
//import luxLogo from "../img/lux-logo-2.svg";
import tokenLogo from "../img/shmLogo.svg";
import maticLogo from "../img/matic.svg";
import bscLogo from "../img/bsc2.svg";
import { Blank, FormDown } from 'grommet-icons';

const cookies = new Cookies();
var pktTID;
var provider;
var signer;
var TeleportContractVault = null;
var cookiesRetrieved = false;
var fromTeleportAddr;
var Token_From_Con;
var TeleportContractBurn, TeleportContractMint, TokenContractBurn, TokenContractMint;
var toTokenAddress = null;
var fromTokenAddress = null;
var fromNetRadio, toNetRadio, fromChainId, toChainId;
var contractsAssigned = false;

var toTargetAddrHash, toTokenAddrHash, toNetIdHash, signature, hashedTxId, tokenAddrHash, vault;
var dv, dv1, dv3, dv4, dv5;
var vault = false;
var initialAmt, amt;
var mm_provider, usrBalance, loc;
var completePhase = false;
var tokenName = null;
var msgSig = null;
var evmToAddress = null;
var nonce = 0;
var msg ="Sign to prove you are initiator of transaction.";
var cookieArr = ['amount', 'cnt', 'fromNetId', 'toNetId', 'tx', 'msgSig', 'tokenName', 'evmToAddress','toTokenAddress', 'fromNetRadio', 'toNetRadio', 'nonce', 'vault'];
var thisAccount = null;

/* Net Id  inits stuff */
var fromNetId = 8080;
var toNetId = 4;
var nativeNetId = fromNetId; // Intially set to the native net

/* Icons */
/*export const LuxIcon = props => (
  <Blank {...props}>
    <svg viewBox="4 4 32 32" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
      <image href={tokenLogo} height="32" width="32"/>
    </svg>
  </Blank>
);*/

export const TokenA = props => (
  <Blank {...props}>
    <svg viewBox="4 4 32 32" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
      <image href={tokenLogo} height="32" width="32"/>
    </svg>
  </Blank>
);

export const TokenB = props => (
  <Blank {...props}>
    <svg viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
      <image href={bscLogo} height="24" width="24"/>text
    </svg>
  </Blank>
);

export const TokenC = props => (
  <Blank {...props}>
    <svg viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
      <image href={ethLogo} height="24" width="24"/>text
    </svg>
  </Blank>
);


/* Optional AccessLists */
var useAccessLists = true;

/* Chain mappings: --> Note: alter instantiations as well */

// ***
//var LBTC_Lux = null;
//var LBTC_Eth = null;
var Token_Eth = null;
var Token_Shm = null;
var Token_Bsc = null;
//var Teleport_Lux = null;
var Teleport_Eth = null;
var Teleport_Shm = null;
var Teleport_Bsc = null;

const chainA ="SHM";
const chainB ="BSC";
const chainC ="Ethereum";

var networkType = "testnet";
const chainParams = new Map();
chainParams.set("97", {chainName:"BSC", rpcUrl:"https://data-seed-prebsc-1-s1.binance.org:8545/", nativeCurrencyName:"BNB", nativeCurrencySymbol:"BNB", nativeCurrencyDecimals:"18", blockExplorerUrl:"https://testnet.bscscan.com"});
chainParams.set("4", {chainName:"Ethereum", rpcUrl:"https://rpc-mainnet.Ethereumvigil.com", nativeCurrencyName:"Ethereum", nativeCurrencySymbol:"Ethereum", nativeCurrencyDecimals:"18", blockExplorerUrl:"https://explorer.Ethereum.network"});
chainParams.set("80001", {chainName:"Mumbai Testnet", rpcUrl:"https://rpc-mumbai.Ethereumvigil.com/", nativeCurrencyName:"Ethereum", nativeCurrencySymbol:"Ethereum", nativeCurrencyDecimals:"18", blockExplorerUrl:"https://mumbai-explorer.Ethereum.today"});
chainParams.set("43114", {chainName:"AVAX", rpcUrl:"https://api.avax.network/ext/bc/C/rpc", nativeCurrencyName:"AVAX", nativeCurrencySymbol:"AVAX", nativeCurrencyDecimals:"18", blockExplorerUrl:"https://cchain.explorer.avax.network/"});
chainParams.set("250", {chainName:"Fantom", rpcUrl:"https://rpc.ftm.tools/", nativeCurrencyName:"FTM", nativeCurrencySymbol:"FTM", nativeCurrencyDecimals:"18", blockExplorerUrl:"https://ftmscan.com"});
chainParams.set("8080", {chainName: "SHM", rpcUrl:"https://liberty10.shardeum.org/", nativeCurrencyName:"Shardeum", nativeCurrencySymbol:"SHM", nativeCurrencyDecimals:"18", blockExplorerUrl:"https://t.co/fL4Cxx1ocA"});

const chainData = new Map();
chainData.set("BSC", {chainId: "97", logo: "TokenB"});
chainData.set("Ethereum", {chainId: "4", logo: "TokenC"});
chainData.set("SHM", {chainId: "8080", logo: "TokenA"});
chainData.set("Mumbai Testnet", {chainId: "80001"});
chainData.set("AVAX", {chainId: "43114"});
chainData.set("Fantom", {chainId: "250"});

const addressMap = new Map();
addressMap.set("Ethereum", {WSHM: addresses.WSHM_Eth, Token: addresses.Token_Eth, Teleport: addresses.Teleport_Eth});
addressMap.set("SHM", {WSHM: addresses.WSHM_Shm, Token: addresses.Token_Shm, Teleport: addresses.Teleport_Shm});
addressMap.set("BSC", {WSHM: addresses.WSHM_Bsc, Token: addresses.Token_Bsc, Teleport: addresses.Teleport_Bsc});
// *** //

var formWrapStyle = {
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.161)",
    width: "85%"
  }
  var formWrapStyleMed = {
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.161)",
    width: "80%"
  };
  var formWrapStyleMob = {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    minWidth: "50vw",
    width: "auto"
  };

function getImageForTokenName(tokenName){
  var imageLink;
  switch(tokenName) {
      case "WSHM":
        imageLink = 'https://lux.wpkt.cash/tokenLogo.png'; //'https://shm.odapp.io/tokenLogo.png';
        break;
  }

  return imageLink;
}


async function addCoin(){

    const tokenSymbol = tokenName;
    const tokenDecimals = 18;
    const tokenImage = getImageForTokenName(tokenName);
    await setNets();
    console.log('toTokenAddress', toTokenAddress);

    try {

        await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more!
                options: {
                    address: toTokenAddress, // The address that the token is at.
                    symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                    decimals: tokenDecimals, // The number of decimals in the token
                    image: tokenImage, // A string url of the token logo
                },
            },
        });

    } catch (error) {
        console.log(error);
    }
}

function getBlockHeight(str){
  var arr = str.split('|');
  return arr[1];
}


async function switchChain(chainId){
  if (window.ethereum) {
     try {
       // check if the chain to connect to is installed
       await window.ethereum.request({
         method: 'wallet_switchEthereumChain',
         params: [{ chainId: chainId }],
       });
     } catch (error) {

       // Error indicates that the chain has not been added to MetaMask
       if (error.code === 4902) {
         try {
           console.log('Failed to switch.');

            // Adds a wider range of chains with more detail
            console.log('toNetIdX:', toNetId, chainParams.get(toNetId.toString()).chainName, chainParams.get(toNetId.toString()).rpcUrl, chainParams.get(toNetId.toString()).nativeCurrencyName, chainParams.get(toNetId.toString()).nativeCurrencySymbol, chainParams.get(toNetId.toString()).nativeCurrencyDecimals,chainParams.get(toNetId.toString()).blockExplorerUrl);

           await window.ethereum.request({
             method: 'wallet_addEthereumChain',
             params: [{
                 "chainId": chainId,
                 "chainName": chainParams.get(toNetId.toString()).chainName,
                 "rpcUrls": [
                     chainParams.get(toNetId.toString()).rpcUrl
                 ],
                 "nativeCurrency": {
                     "name": chainParams.get(toNetId.toString()).nativeCurrencyName,
                     "symbol": chainParams.get(toNetId.toString()).nativeCurrencySymbol,
                     "decimals": chainParams.get(toNetId.toString()).nativeCurrencyDecimals
                 },
                 "blockExplorerUrls": [
                     chainParams.get(toNetId.toString()).blockExplorerUrl
                 ]
             }]
           });
         } catch (addError) {
           console.log('Failed to wallet_addEthereumChain.');
           console.error(addError);
         }

       }//
       console.error(error);
     }
  }
}

async function completeTransaction(){
  console.log("CompleteTransaction - switching to:", toChainId);
  console.log("Signature:", signature);
  completePhase = true;
  var complete = false;
  var keyExists = false;
  getSavedCookies();
  await setNets();
  console.log("Switching Chains...");

  var switching = await switchChain(toChainId);

  try {
          if (!TeleportContractMint){
            console.log('TeleportContractMintError: likely bad contract mint object', TeleportContractMint);
          }

          // Check if key exists to know if transaction was already completed.
          keyExists = await TeleportContractMint.keyExistsTx(signature);
          console.log('keyExists', keyExists);

          if (keyExists){
              complete = true;
              console.log("Payout Status?:", complete);
              dv4.style.display= 'none';
              dv5.style.display= 'none';
              await cookieReSetter();//reset cookie

          }
  }

  catch (err){
      console.log('Transaction Failure.', err);
      if (err !=null &&err.toString().includes('unknown account')){
          dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Please connect your metamask wallet.</h4>";
      }
      else{
          dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>"+err+"</h4>";//err.data.message
      }
      //}
      dv1.style.display= 'none';
      dv3.style.display= 'none';
      dv4.style.display= 'none';
      dv5.style.display= 'block';
      return;
  }

  console.log('amt:', amt, 'toTokenAddress:', toTokenAddress, 'TID:', hashedTxId, 'ethAddr:', evmToAddress, 'Sig:',signature, 'Complete:', complete);

  if (signature && hashedTxId && !complete){

      if (mm_provider) {

          dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Transaction Pending...</h4>";
          dv.style.display= 'block';

          try{

              toNetIdHash = Web3.utils.keccak256(toNetId.toString());
              toTargetAddrHash = Web3.utils.keccak256(evmToAddress);//Web3.utils.keccak256(evmToAddress.slice(2));
              console.log("toTargetAddrHash", toTargetAddrHash, "toNetIdHash", toNetIdHash);
              console.log("Inputs:", amt, hashedTxId.toString(), evmToAddress.toString(), signature, toTokenAddress.toString(), toNetId.toString(), vault);
              console.log('bridgeMintStealth("'+amt.toString()+'","'+hashedTxId.toString()+'","'+evmToAddress.toString()+'","'+signature.toString()+'","'+toTokenAddress.toString()+'","'+toNetId.toString()+'","'+vault.toString()+'")');
              var codeHash = await provider.getCode(toTokenAddress.toString());

              // Access List - get the chainID and use addresses from correct chain - mint needs WHSM
              if (toNetId == nativeNetId && useAccessLists){
                  console.log('codeHash', codeHash);


                  var tx = await TeleportContractMint.bridgeMintStealth(amt, hashedTxId.toString(), evmToAddress.toString(), signature, toTokenAddress.toString(), toNetId.toString(), vault.toString(), {accessList: [
                  {
                    address: toTokenAddress.toString(),
                    storageKeys: [
                        '0x1b4f47d6ca6038ce4517c9c054709bb50eb5d61a39817189c72a651a1ea97023',
                        '0x0000000000000000000000000000000000000000000000000000000000000002',
                        '0x465b2a221cd80d432c6d530e3dcd93a11866f387d11dfe27b2ac8f8fbefb1f73',
                        codeHash, //Code hash
                     ]
                   }]
                 });
              }
              else {
                var tx = await TeleportContractMint.bridgeMintStealth(amt, hashedTxId.toString(), evmToAddress.toString(), signature, toTokenAddress.toString(), toNetId.toString(), vault.toString());
              }

              //var tx = await TeleportContractMint.bridgeMintStealth(amt, hashedTxId.toString(), evmToAddress.toString(), signature, toTokenAddress.toString(), toNetId.toString(), vault.toString());

              console.log('TX:',tx);
              dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Pending Transaction ID:</h4><Text margin='small' >" + tx.hash + "</Text><h4 style={{backgroundColor: '#2B2F36'}}>Please wait for on-chain confirmation...</h4>";
              dv1.style.display= 'block';

              TeleportContractMint.once("BridgeMinted", async (recip, tokenAddr, amount) => {
                  var feesNoWei = 0;
                  console.log('Recipient:',recip);
                  console.log('Amount:', amount.toString());
                  var amtNoWei = Web3.utils.fromWei(amount.toString());
                  initialAmt = Web3.utils.fromWei(amt.toString());
                  console.log('amtNoWei', amtNoWei, initialAmt);
                  if (Number(amtNoWei) > 0){
                    var fees = Number(initialAmt) - (Number(amtNoWei));
                    feesNoWei = fees.toFixed(10);
                  }

                  console.log("Amount:", amtNoWei, 'Fees:', feesNoWei, 'Token Address:', tokenAddr);

                  if (Number(amtNoWei) > 0 && (toNetId != nativeNetId)) {
                      if (receipt2 !== undefined) {
                          dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Pending Transaction Complete.</h4><h4 style={{backgroundColor: '#2B2F36'}}><b>Your transaction hash is " + receipt2.transactionHash + "</h4>";
                      }
                      dv4.style.display= 'none';
                      dv5.style.display= 'none';
                      dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>You received "+amtNoWei+" "+tokenName+" tokens.</h4>";//LBTC
                      dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Your fees were "+feesNoWei+" "+tokenName+" tokens.</h4>";
                      dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>If the Teleport token hasn't already been added to your wallet yet, use the button below to add it. Make sure to add it to the right MetaMask account.</h4>";
                      dv3.style.display= 'block';
                      dv1.style.display= 'none';
                      complete = true;
                      await cookieReSetter();
                      return;

                  }
                  else {
                      dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Transaction Failed.</h4>";
                      dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Bad transaction. Check your sender / recipient address pair or transaction hash. </h4>";
                      dv1.style.display= 'none';
                      dv4.style.display= 'block';//
                      return;
                  }

              });

              var receipt2 = await tx.wait();
              console.log('Receipt:', receipt2, (receipt2.status === 1));

              if (receipt2.status !== 1) {
                  console.log('Transaction Failure.');
                  dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Your transaction failed</h4>";
                  dv.innerHTML += "<h6 style={{backgroundColor: '#2B2F36'}}>It's possible you have already claimed this transaction.</h6>";
                  dv1.style.display= 'none';
                  dv4.style.display= 'block';//
                  return;
              }
              else {
                  console.log('Receipt received');
                  TeleportContractMint.removeAllListeners(["BridgeMinted"]);
                  dv4.style.display= 'none';
                  dv5.style.display= 'none';

                  if (!complete && (toNetId != nativeNetId)){
                    dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>If the token hasn't already been added to your wallet yet, use the button below to add it. Make sure to add it to the right MetaMask account.</h4>";
                    dv1.style.display= 'none';
                    dv3.style.display= 'block';
                    complete = true;
                  }


                  if (toNetId == nativeNetId) { // Will need to unvault now

                    // Access List
                    if (useAccessLists){
                        //var codeHash = await provider.getCode(toTokenAddress.toString());
                        console.log('codeHash', codeHash);
                        var txUnvault = await TeleportContractVault.bridgeBurnAndUnvaultPayable(amt, toTokenAddress.toString(), {accessList: [
                        {
                          address: toTokenAddress.toString(),
                          storageKeys: [
                              '0x1b4f47d6ca6038ce4517c9c054709bb50eb5d61a39817189c72a651a1ea97023',
                              '0x0000000000000000000000000000000000000000000000000000000000000002',
                              '0x465b2a221cd80d432c6d530e3dcd93a11866f387d11dfe27b2ac8f8fbefb1f73',
                              codeHash, //Code hash
                           ]
                         }]
                       });
                    }
                    else {
                      var txUnvault = await TeleportContractVault.bridgeBurnAndUnvaultPayable(amt, toTokenAddress.toString());
                    }

                    //var txUnvault = await TeleportContractVault.bridgeBurnAndUnvaultPayable(amt, toTokenAddress.toString());

                    TeleportContractVault.once("VaultReleased", async (recip, amount) => {
                        var feesNoWei = 0;
                        console.log('Recipient:',recip);
                        console.log('Amount:', amount.toString());
                        var amtNoWei = Web3.utils.fromWei(amount.toString());
                        initialAmt = Web3.utils.fromWei(amt.toString());

                        if (Number(amtNoWei) > 0){
                          var fees = Number(initialAmt) - (Number(amtNoWei));
                          feesNoWei = fees.toFixed(10);
                        }

                        console.log("Amount:", amtNoWei, 'Fees:', feesNoWei);


                        if (Number(amtNoWei) > 0) {
                            if (receipt3 !== undefined) {
                                dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Pending Transaction Complete.</h4><h4 style={{backgroundColor: '#2B2F36'}}><b>Your transaction hash is " + receipt2.transactionHash + "</h4>";
                            }

                            dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>You received "+amtNoWei+" "+tokenName+" coins.</h4>";
                            dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Your fees were "+feesNoWei+" "+tokenName+" coins.</h4>";
                            //dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>If the Teleport token hasn't already been added to your wallet yet, use the button below to add it. Make sure to add it to the right MetaMask account.</h4>";
                            //dv3.style.display= 'block';
                            dv1.style.display= 'none';
                            complete = true;
                            cookieReSetter();
                            return;

                        }

                        else {
                            dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Transaction Failed.</h4>";
                            dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Bad transaction. Check your sender / recipient address pair or transaction hash. </h4>";
                            dv1.style.display= 'none';
                            dv4.style.display= 'block';//
                            return;
                        }

                    });

                    var receipt3 = await txUnvault.wait();

                    console.log('Unvault Receipt:', receipt3, (receipt3.status === 1));

                    if (receipt3.status !== 1) {
                        console.log('Transaction Failure.');
                        dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Your transaction failed</h4>";
                        dv.innerHTML += "<h6 style={{backgroundColor: '#2B2F36'}}>It's possible you have already claimed this transaction.</h6>";
                        dv1.style.display= 'none';
                        dv4.style.display= 'block';//
                        return;
                    }
                    else {
                        console.log('Receipt received');
                        TeleportContractMint.removeAllListeners(["VaultReleased"]);
                        cookieReSetter();
                        if (!complete){
                          dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Transaction complete, coins unvaulted.</h4>";
                          var amtLessFeeNoWei = Web3.utils.fromWei(amt.toString()) * .99;
                          dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>You received "+amtLessFeeNoWei+" coins.</h4>";
                          dv1.style.display= 'none';
                          //dv3.style.display= 'block';
                          complete = true;
                        }
                    }

                  }

              }

          }
          catch (err) {
              console.log('Error:', err);
              dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Your transaction failed</h4>";
              if (err.toString().includes('unknown account')){
                dv.innerHTML += "<h6 style={{backgroundColor: '#2B2F36'}}>Please connect your metamask wallet to this site.</h6>";
              }
              else if (err.code.toString().includes('BadSig')){
                dv.innerHTML += "<h6 style={{backgroundColor: '#2B2F36'}}>Bad Signature</h6>";
              }
              else if (err.code.toString().includes('DupeTX')){
                dv.innerHTML += "<h6 style={{backgroundColor: '#2B2F36'}}>Is it possible you have already claimed this transaction?</h6>";
              }
              else {
                dv.innerHTML += "<h6 style={{backgroundColor: '#2B2F36'}}>"+err.message+"</h6>";
              }
              dv4.style.display= 'block';
              dv1.style.display= 'none';
              return;
          }

      }
      else {
          console.log('Metamask not running. Is it installed?');
          dv.style.display='block';
          dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Please Install <a href='https://metamask.io/download.html' style='color:#F0B90C;' />Metamask</a></h4>";
          return;
      }
  }
  else {
      dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Transaction Failure</h4>";
      if (complete){
        dv.innerHTML += "<h6 style={{backgroundColor: '#2B2F36'}}>This transaction has already been paid out.</h6>";
        await cookieReSetter();//reset cookie
      }
      else{
        dv.innerHTML += "<h6 style={{backgroundColor: '#2B2F36'}}>Can\'t retrieve data from bridge servers.</h6>";
      }
      dv.style.display= 'block';
      dv1.style.display= 'none';
      dv4.style.display= 'block';
      return;
  }
}

function setNet(){

    console.log('chain id:', chainData.get(fromNetRadio.toString()).chainId);
    fromNetId = chainData.get(fromNetRadio.toString()).chainId;
    //toNetId = chainData.get(toNetRadio.toString()).chainId;

    console.log("fromNetRadio", fromNetRadio, fromNetId);
}

function setNets(){

  console.log("fromNetRadio1:", fromNetRadio);
  console.log("toNetRadio1:", toNetRadio);

  try{
    fromNetId = chainData.get(fromNetRadio.toString()).chainId;
    toNetId = chainData.get(toNetRadio.toString()).chainId;
  }
  catch (e){
    console.log("Null fromNetRadio or toNetRadio:", e);
    return;
  }

  TeleportContractVault = new Contract(addresses.TeleportContractVault, abis.TeleportContractVault, signer);
  const teleportConMap = new Map();
  const tokenConMap = new Map();

  if (!contractsAssigned){

    // *** Get contracts.
    Teleport_Eth = new Contract(addresses.Teleport_Eth, abis.Teleport, signer);
    Teleport_Shm = new Contract(addresses.Teleport_Shm, abis.Teleport, signer);
    Teleport_Bsc = new Contract(addresses.Teleport_Bsc, abis.Teleport, signer);
    //Teleport_Lux = new Contract(addresses.Teleport_Lux, abis.Teleport, signer);
    Token_Eth = new Contract(addresses.Token_Eth, abis.Token, signer);
    Token_Shm = new Contract(addresses.Token_Shm, abis.Token, signer);
    Token_Bsc = new Contract(addresses.Token_Bsc, abis.Token, signer);
    //Token_Lux = new Contract(addresses.Token_Lux, abis.Teleport, signer);
    // ***

    contractsAssigned = true;
  }

  if (fromNetRadio == 'Select' || toNetRadio == 'Select'){
    toNetRadio = chainParams.get(toNetId.toString()).chainName;
    fromNetRadio = chainParams.get(fromNetId.toString()).chainName;
  }

  // ***
  teleportConMap.set("Ethereum", Teleport_Eth);
  teleportConMap.set("SHM", Teleport_Shm);
  teleportConMap.set("BSC", Teleport_Bsc);
  //teleportConMap.set("LUX", Teleport_Lux);

  tokenConMap.set("Ethereum", Token_Eth);
  tokenConMap.set("SHM", Token_Shm);
  tokenConMap.set("BSC", Token_Bsc);
  //tokenConMap.set("LUX", Token_Lux);
  // ***

  console.log('teleportConMap', teleportConMap, 'tokenConMap', tokenConMap);
  TeleportContractBurn = teleportConMap.get(fromNetRadio.toString());
  TeleportContractMint = teleportConMap.get(toNetRadio.toString());
  TokenContractBurn= tokenConMap.get(fromNetRadio.toString());
  TokenContractMint = tokenConMap.get(fromNetRadio.toString());

  toTokenAddress = addressMap.get(toNetRadio.toString()).Token;
  fromTokenAddress = addressMap.get(fromNetRadio.toString()).Token;

  Token_From_Con = new Contract(fromTokenAddress, abis.Token, signer);

  console.log("fromNetRadio2:", fromNetRadio, fromNetId);
  console.log("toNetRadio2:", toNetRadio, toNetId);

  fromChainId = ("0x"+Number(fromNetId).toString(16)).toString();
  toChainId = ("0x"+Number(toNetId).toString(16)).toString();
  console.log('toTokenAddress', toTokenAddress, '**TeleportContractMint:', TeleportContractMint,  Teleport_Shm);
  return Token_From_Con;

}


function getCurrentNetworkName(fromNetId){

  var currentNetworkName;
  currentNetworkName = chainParams.get(fromNetId.toString()).chainName;
  return currentNetworkName;
}

async function checkNetsMatch(){
  dv = document.getElementById("output");
  dv1 = document.getElementById("spin");
  dv3 = document.getElementById("addToken");
  dv4 = document.getElementById("continue");
  dv.style.display= 'none';
  dv3.style.display= 'none';
  //dv4.style.display= 'none';

  var network = await window.ethereum.request({ method: 'net_version' })
  var currentNetworkName = getCurrentNetworkName(fromNetId);
  console.log('currentNetworkName:',currentNetworkName, fromNetId, network);

  if (Number(network)!== Number(fromNetId)){
      dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Connect Metamask to "+currentNetworkName+" "+networkType+" and resubmit.</h4>";
      dv.style.display= 'block';
      dv1.style.display= 'none';
      return false;
  } else{
    return true;
  }
}

async function getProvider(){
  mm_provider = await detectEthereumProvider();

  if (mm_provider !== window.ethereum){
      console.log('Multiple wallets installed');
      return;
  }
  else {
      console.log('window.ethereum is current wallet.');
      provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      signer = provider.getSigner();
  }
}

if (typeof window.ethereum !== 'undefined') {

  window.ethereum.on('accountsChanged', function (accounts) {
    if (loc && loc.pathname === '/TeleportVault'){
      dv = document.getElementById("output");
      dv.style.display= 'none';
      let balOk = checkBalanceInput(initialAmt);
    }

  });

  window.ethereum.on('networkChanged', function (network) {
    console.log("complete:", completePhase);
    if (loc && loc.pathname === '/TeleportVault' && (!completePhase)){
      dv = document.getElementById("output");
      dv.style.display= 'none';
      setNets();
      console.log("complete:", completePhase);
      checkNetsMatch();
    }
  });
}


function balanceError(){
  dv = document.getElementById("output");
  dv.style.display= 'block';
  console.log("mm_provider", signer);
  if (!mm_provider){
    dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Your MetaMask is not connected to this site.</h4>";
  }
  else {
    dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Your balance is too low for this transaction. Make sure to connect the correct account to this site.</h4>";
  }
}

async function checkBalanceInput(value){
  if ((fromNetRadio != 'Select') && (toNetRadio != 'Select')){
    dv = document.getElementById("output");
    dv.style.display= 'none';
    usrBalance = await userBalance();

    console.log('User Balance:', Number(value), Number(usrBalance), Number(value)>Number(usrBalance));

    if (value && usrBalance){
      if (Number(value)>Number(usrBalance)){
        balanceError();
        return false;
      }
      else{
          return true;
      }
    }
    else {
      return false;
    }

  }
}

async function userBalance(){

  Token_From_Con = await setNets(); // sets correct Teleport
  let correctNets = await checkNetsMatch();

  if (!correctNets){
    console.log('Wrong network');
    return;
  }

  thisAccount = await provider.listAccounts();
  console.log('Signer is:', thisAccount[0], Token_From_Con, fromNetId);
  console.log('fromNetId != nativeNetId', (fromNetId != nativeNetId));


  try {
    usrBalance = (fromNetId == nativeNetId) ? Web3.utils.fromWei((await provider.getBalance(thisAccount[0].toString())).toString()) : Web3.utils.fromWei((await Token_From_Con.balanceOf(thisAccount[0])).toString());
    if (isNaN(usrBalance)){
      usrBalance = 0;
    }

    console.log('Balance:', usrBalance);
    console.log('Token_From_Con:', Token_From_Con.address);
    return usrBalance;
  }
  catch (err){
    console.log('Error:', err);
  }

}


function handleValueFromDrop(valueFrom, setValueFrom, setValueTo){
  fromNetRadio=valueFrom;
  setValueFrom(valueFrom);
  setNet();
  checkNetsMatch();
  if ((fromNetRadio != toNetRadio) && (fromNetRadio != 'Select' && toNetRadio != 'Select')){
    let balOk = checkBalanceInput(initialAmt);
  }
}

function handleValueToDrop(valueTo, setValueFrom, setValueTo){
  toNetRadio=valueTo;
  setValueTo(valueTo);

}


async function handleInput(e){

    // Reset
    signature = null;
    hashedTxId = null;

    e.preventDefault();

    // Get Details
    initialAmt = e.value.Amt.trim();

    dv = document.getElementById("output");
    dv1 = document.getElementById("spin");
    dv3 = document.getElementById("addToken");
    dv4 = document.getElementById("continue");
    dv5 = document.getElementById("retry");

    // Reset Interface
    dv.style.display= 'none';
    dv1.style.display= 'none';
    dv3.style.display= 'none';
    dv4.style.display= 'none';
    dv5.style.display= 'none';


    if (!mm_provider){
        console.log('Metamask not running. Is it installed?');
        dv.style.display='block';
        dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Please Install <a href='https://metamask.io/download.html' style='color:#F0B90C;' />Metamask</a></h4>";
        return;
    }

    if(isNaN(initialAmt)){
      dv.style.display= 'block';
      console.log('Teleport amount is not a number');
      dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Your amount must be a number.</h4>";
      return;
    }

    initialAmt = initialAmt.toString();
    console.log(toNetRadio, fromNetRadio, initialAmt);

    // Make sure networks are different
    if (fromNetRadio === 'Select' ||  toNetRadio === 'Select'){
      console.log("Networks are not different.");
      dv.style.display= 'block';
      dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>You must select to and from networks.</h4>";
      return;
    }

    if (!toNetRadio || !fromNetRadio){
      console.log("Network selection error.");
      dv.style.display= 'block';
      dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>NetworkSelectionError: Please select your from and to networks again.</h4>";
      return;
    }

    // Make sure networks are different
    if (fromNetRadio === toNetRadio){
      console.log("Networks are not different.");
      dv.style.display= 'block';
      dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Your from and to networks must be different.</h4>";
      return;
    }

    // Get contracts.
    /*Teleport_Lux = new Contract(addresses.Teleport_Lux, abis.Teleport, signer);
    Teleport_Eth = new Contract(addresses.Teleport_Eth, abis.Teleport, signer);
    TeleportContractBurn = null;*/

    // Set correct contracts for from and to chains
    setNets();
    console.log("fromNetRadio", fromNetRadio, "toNetRadio", toNetRadio);
    var switching = await switchChain(fromChainId);

    let correctNets = checkNetsMatch();
    if (!correctNets)
      return;

    let balOk = await checkBalanceInput(initialAmt);
    if(!balOk){
      return;
    }

    console.log('signer:', signer);
    msgSig = await signer.signMessage(msg);
    console.log('msgSig:', msgSig);


    try{

        dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Transaction Pending...</h4>";
        dv.style.display= 'block';

        amt = Web3.utils.toWei(initialAmt.toString()); // Convert intialAmt toWei
        console.log("TeleportContractBurn:", TeleportContractBurn.address);

        console.log('fromTokenAddress:', fromTokenAddress);
        var codeHash = await provider.getCode(fromTokenAddress.toString());

        if(fromNetId == nativeNetId)  {// if fromNetId == nativeNetId then we approve and vault

          console.log("bridgeVaultAndMintPayable("+fromTokenAddress.toString()+",{from:"+thisAccount[0].toString()+", value:"+amt.toString()+"})");

          // Access List - get the chainID and use addresses from correct chain - mint needs WHSM
          if (useAccessLists){
              console.log('codeHash', codeHash);
              var txVault = await TeleportContractVault.bridgeVaultAndMintPayable(fromTokenAddress.toString(),{from: thisAccount[0].toString(), value:amt.toString(),
                accessList: [
                {
                  address: fromTokenAddress.toString(),
                  storageKeys: [
                      '0x1b4f47d6ca6038ce4517c9c054709bb50eb5d61a39817189c72a651a1ea97023',
                      '0x0000000000000000000000000000000000000000000000000000000000000002',
                      '0x465b2a221cd80d432c6d530e3dcd93a11866f387d11dfe27b2ac8f8fbefb1f73',
                      codeHash, //Code hash
                   ]
                }]
              });
          }
          else {
            var txVault = await TeleportContractVault.bridgeVaultAndMintPayable(fromTokenAddress.toString(),{from: thisAccount[0].toString(), value:amt.toString()});
          }

          //var txVault = await TeleportContractVault.bridgeVaultAndMintPayable(fromTokenAddress.toString(),{from: thisAccount[0].toString(), value:amt.toString()});

          // Listen for vault completion
          TeleportContractVault.once("VaultDeposit", async (caller, amount) => {
              console.log('Vaulted.');
              console.log('Amount:', amount.toString());
          });

          var receiptVault = await txVault.wait();
          console.log('Vault Receipt:', receiptVault, (receiptVault.status === 1));

          if (receiptVault.status !== 1) {
              console.log('Vault Transaction Failure 1.');
              dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Your transaction failed.</h4>";
              dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Do you have enough coins to bridge?</h4>";
              dv.style.display= 'block';
              dv1.style.display= 'none';
              return;
          }

          else {
              console.log('Receipt received 1');
              TeleportContractVault.off("VaultDeposit");
              TeleportContractVault.removeAllListeners(["VaultDeposit"]);

          }

        }

        var tx = await TeleportContractBurn.bridgeBurn(amt, fromTokenAddress); // Burn coins

        dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Pending Transaction ID:</h4><Text margin='small' >" + tx.hash + "</Text><h4 style={{backgroundColor: '#2B2F36'}}>Please wait for on-chain confirmation...</h4>";
        dv1.style.display= 'block';

        var cnt = 0;
        vault = false;

        // Listen for burning completion
        TeleportContractBurn.once("BridgeBurned", async (caller, amount) => {
            console.log('Recipient:', caller);
            console.log('Amount:', amount.toString());
        });

        var receipt = await tx.wait();
        console.log('Receipt:', receipt, (receipt.status === 1));

        if (receipt.status !== 1) {
            console.log('Transaction Failure.');
            dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Your transaction failed.</h4>";
            dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Did you pay the network fees?</h4>";
            dv.style.display= 'block';
            dv1.style.display= 'none';
            return;
        }

        else {
            console.log('Receipt received 2');
            TeleportContractBurn.off("BridgeBurned");
            TeleportContractBurn.removeAllListeners(["BridgeBurned"]);

            if (cnt == 0){
              console.log('cookie array:', amt, cnt, fromNetId, toNetId, tx, msgSig, tokenName);
              await handleMint(amt, cnt, fromNetId, toNetId, tx, 0, vault);
              cnt++;
            }
        }
    }

    catch (err) {
        console.log('Error 3:', err);
        dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Your transaction failed</h4>";
        if (err.toString().includes('unknown account')){
          dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Please connect your metamask wallet to this site.</h4>";
        }
        else if (err.code.toString().includes('-32603')){
          dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>"+err.data.message+"</h4>";
        }
        else {
          dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Check you have enough coin to afford this transaction\'s gas fees.</h4>";
        }
        dv.style.display= 'block';
        dv1.style.display= 'none';
        return;
    }
}

/* Returns the verified sig which should be the sig with the highest count. Usually a set of 1. */
function verifySignature(result){
  let sigMap = new Map();
  let sigCount = 0;
  let highestCount = 0;
  let highCountId = 0;
  let i = 0;

  console.log('Verification of signature.');
  for (i in result) {
    if (i === 0){
      sigCount += 1;
      sigMap.set(result[i].signature, sigCount);
      highestCount = sigCount;
      highCountId = i;
    }
    else{
      sigCount = Number(sigMap.get(result[i].signature));
      if (sigCount) {
        sigCount += 1;
        sigMap.set(result[i].signature, sigCount);
        if (sigCount > highestCount){
          highestCount = sigCount;
          highCountId = (highCountId != i) ? i : highCountId;
        }
      }
      else{
        sigMap.set(result[i].signature, 1); // A different sig has been found
      }
    }
  }
  return result[highCountId].data;

}


async function handleMint(amount, cnt, fromNetId, toNetId, tx, nonce, vault){
  console.log('In Handle Mint...');
  nonce = (!nonce) ? 0 : nonce;
  amt = amount; // in wei
  var amtNoWei = Web3.utils.fromWei(amount.toString());
  console.log('amtNoWei', Number(amtNoWei), 'cnt', cnt);
  var txid = tx.hash;
  var toNetId;
  console.log('txid:', txid);
  dv = document.getElementById("output");
  dv1 = document.getElementById("spin");
  dv3 = document.getElementById("addToken");
  dv4 = document.getElementById("continue");
  dv5 = document.getElementById("retry");
  dv5.style.display= 'none';
  dv.style.display= 'none';
  dv3.style.display= 'none';
  console.log('Nonce:', nonce);

  if (fromNetRadio == 'Select' || toNetRadio == 'Select'){
    toNetRadio = chainParams.get(toNetId.toString()).chainName;
    fromNetRadio = chainParams.get(fromNetId.toString()).chainName;
  }

  if (Number(amtNoWei) > 0 && cnt == 0) {

      dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Requesting Bridge Signature For hash </h4><h4 style={{backgroundColor: '#2B2F36'}}><b> " + txid + "</h4>";
      getSavedCookies();

      dv1.style.display= 'none';
      toNetIdHash = Web3.utils.keccak256(toNetId.toString());
      toTargetAddrHash = Web3.utils.keccak256(evmToAddress);//Web3.utils.keccak256(evmToAddress.slice(2));
      toTokenAddrHash = Web3.utils.keccak256(toTokenAddress);//Web3.utils.keccak256(toTokenAddress.slice(2));
      console.log("toTargetAddrHash", toTargetAddrHash, "toNetIdHash", toNetIdHash, "toTokenAddress", toTokenAddress, "toTokenAddrHash", toTokenAddrHash);

      dv1.style.display= 'block';
      //dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Bridge Signature Pending...</h4>";
      //dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Retrieving signature from the bridge network.</h4>";
      dv.style.display= 'block';
      dv1.style.display= 'block';
      dv3.style.display= 'none';

      /*
      //var cmd = "http://localhost:5000/api/v1/getsig/txid/"+txid+"/fromNetId/"+fromNetId+"/toNetIdHash/"+toNetIdHash+"/tokenName/"+tokenName+"/tokenAddrHash/"+toTokenAddrHash+"/msgSig/"+msgSig+"/toTargetAddrHash/"+toTargetAddrHash;
      //var cmd = "https://teleporter.wpkt.cash/api/v1/getsig/txid/"+txid+"/fromNetId/"+fromNetId+"/toNetIdHash/"+toNetIdHash+"/tokenName/"+tokenName+"/tokenAddrHash/"+toTokenAddrHash+"/msgSig/"+msgSig+"/toTargetAddrHash/"+toTargetAddrHash;
      */

      nonce++;
      await cookieSetter([amount, cnt, fromNetId, toNetId, tx, msgSig, tokenName, evmToAddress, toTokenAddress, fromNetRadio, toNetRadio, nonce, vault]);
      console.log("/api/v1/getsig/txid/"+txid+"/fromNetId/"+fromNetId+"/toNetIdHash/"+toNetIdHash+"/tokenName/"+tokenName+"/tokenAddrHash/"+toTokenAddrHash+"/msgSig/"+msgSig+"/toTargetAddrHash/"+toTargetAddrHash+"/nonce/"+nonce);
      let mpcPeerArr = ["mnode.wpkt.cash", "mnode.odapp.io"];
      axios.all(mpcPeerArr.map((mpcNode) => axios.get("https://"+mpcNode+"/api/v1/getsig/txid/"+txid+"/fromNetId/"+fromNetId+"/toNetIdHash/"+toNetIdHash+"/tokenName/"+tokenName+"/tokenAddrHash/"+toTokenAddrHash+"/msgSig/"+msgSig+"/toTargetAddrHash/"+toTargetAddrHash+"/nonce/"+nonce))) // Calls endpoints of other MPC nodes
      .then(async (result) => {
          console.log('Peer Notification Complete');
          console.log('Data:', result);
          let verity = verifySignature(result);//Verify that sigs match.
          console.log('verity:', verity);
          result = (verity) ? result[0].data : false;

          if (result.signature && result.hashedTxId){
            // Set globals
            signature = result.signature;
            hashedTxId = result.hashedTxId;
            toTargetAddrHash = result.from;
            tokenAddrHash = result.tokenAddrHash;
            vault = result.vault;
            console.log('hashedTxId:', hashedTxId, 'signature:', signature, 'toTargetAddrHash:', toTargetAddrHash, 'tokenAddrHash:', tokenAddrHash, 'vault', vault);
            dv1.style.display= 'none';
            dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Click continue to complete your transaction.</h4>";
            dv.style.display= 'block';
            dv4.style.display= 'block'; // Show the continue button
            dv5.style.display= 'none';
            //cookieReSetter();
          }

          else if (result.error.toString() === 'EntryAlreadyExistsError')  {
              console.log('Entry already exists error.');
              dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Transaction Failure</h4>";
              dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>You can't claim the same transaction more than once.</h4>";
              dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>If the Teleport token hasn't already been added to your wallet yet, use the button below to add it. </h4>";
              dv.style.display= 'block';
              dv3.style.display= 'block';
              cookieReSetter();
              return;
          }

          else if (result.error.toString() === 'GasTooLowError')  {
              console.log('Gas price error.');
              dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Transaction Failure</h4>";
              dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Non-specific gas price error.</h4>";
              dv.style.display= 'block';
              dv1.style.display= 'none';
              await cookieSetter([amount, cnt, fromNetId, toNetId, tx, msgSig, tokenName, evmToAddress, toTokenAddress, fromNetRadio, toNetRadio, nonce, vault]);
              return;
          }

          else if (result.error.toString() === 'AlreadyProcessingTransactionError' || result.error.toString() === 'SignerDeadError2' || result.error.toString() === 'SignerDeadError3' || result.error.toString() === 'SignerFailError2' || result.error.toString() === 'SignerFailError3')  {
              console.log('Hash sign error.');
              /*dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Transaction Failure</h4>";
              dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Non-specific gas price error.</h4>";
              dv.style.display= 'block';
              dv1.style.display= 'none';*/
              await new Promise(r => setTimeout(r, 5000));//Sleep 5secs and try again
              handleMint(amount, cnt, fromNetId, toNetId, tx, nonce, vault);
              return;
          }

          else if (result.error.toString() === "AlreadyMintedError")  {
              console.log('Already minted error.');
              dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Already Minted Error</h4>";
              dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>It appears these coins were already bridged</h4>";
              dv.style.display= 'block';
              dv1.style.display= 'none';
              await cookieSetter([amount, cnt, fromNetId, toNetId, tx, msgSig, tokenName, evmToAddress, toTokenAddress, fromNetRadio, toNetRadio, nonce, vault]);
              return;
          }
          else {
              console.log('Bad transaction.');
              dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Transaction Failure</h4>";
              dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}> Either transaction doesn't exist or it was for a zero amount.</h4>";
              dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}> It is possible you used a different sender address for this transaction.</h4>";
              dv.style.display= 'block';
              dv1.style.display= 'none';
              await cookieSetter([amount, cnt, fromNetId, toNetId, tx, msgSig, tokenName, evmToAddress, toTokenAddress, fromNetRadio, toNetRadio, nonce, vault]);
              return;
          }

      }).catch(async function(err) {
          console.log("error", err);
          dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Transaction Failure</h4>";
          dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>There was a problem communicating with the bridge servers.</h4>";
          dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Click retry to complete your transaction.</h4>";
          dv.style.display= 'block';
          dv1.style.display= 'none';
          dv4.style.display= 'block'; // Show the handleMint() button -- which takes cookies.amount as args
          dv5.style.display= 'none';
          await cookieSetter([amount, cnt, fromNetId, toNetId, tx, msgSig, tokenName, evmToAddress, toTokenAddress, fromNetRadio, toNetRadio, nonce, vault]);
          return;
      });
      return;
  }
  else {
      dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Transaction Failed.</h4>";
      dv.innerHTML += "<h4 style={{backgroundColor: '#2B2F36'}}>Bad transaction. Check your transaction hash. </h4>";
      dv.style.display= 'block';
      dv1.style.display= 'none';
      await cookieSetter([amount, cnt, fromNetId, toNetId, tx, msgSig, tokenName, evmToAddress, toTokenAddress, fromNetRadio, toNetRadio, nonce, vault]);
      return;
  }

}

function cookieSetter(cookieVals){

  // Iterate and array instead
  for (var i=0; i < cookieArr.length; i++){
    console.log('Cookie Vals', cookieVals[i]);
    cookies.set(cookieArr[i], cookieVals[i], { path: '/' });
    console.log(cookieArr[i], cookies.get(cookieArr[i]));
  }
}

function cookieReSetter(){
  for (var i=0; i < cookieArr.length; i++){
    cookies.set(cookieArr[i], '', { path: '/' });
    console.log(cookieArr[i], cookies.get(cookieArr[i]));
  }
}

function getSavedCookies(){
  dv = document.getElementById("output");

  try {
    if (cookies.get('toNetId')!==null && cookies.get('toNetId')!=='' && toNetId === null){
      toNetId = cookies.get('toNetId');
    }
    if (cookies.get('msgSig')!==null && cookies.get('msgSig')!=='' && msgSig === null){
      msgSig = cookies.get('msgSig');
    }
    if (cookies.get('tokenName')!==null && cookies.get('tokenName')!=='' && tokenName === null){
      tokenName = cookies.get('tokenName');
    }
    if (cookies.get('evmToAddress')!==null && cookies.get('evmToAddress')!=='' && evmToAddress === null){
       evmToAddress = cookies.get('evmToAddress');
    }
    if (cookies.get('toTokenAddress')!==null && cookies.get('toTokenAddress')!=='' && toTokenAddress === null){
      toTokenAddress = cookies.get('toTokenAddress');
    }
    if (cookies.get('toNetId')!==null && cookies.get('toNetId')!=='' && (toNetId === '' || toNetId == null)){
      toNetId = cookies.get('toNetId');
    }
    if (cookies.get('fromNetId')!==null && cookies.get('fromNetId')!=='' && (fromNetId === '' || fromNetId == null)){
      fromNetId = cookies.get('fromNetId');
    }
    if (cookies.get('fromNetRadio')!==null && cookies.get('fromNetRadio')!=='' && (fromNetRadio === '' || fromNetRadio == null)){
      fromNetRadio = cookies.get('fromNetRadio');
    }
    if (cookies.get('toNetRadio')!==null && cookies.get('toNetRadio')!=='' && (toNetRadio === '' || toNetRadio == null)){
      toNetRadio = cookies.get('toNetRadio');
    }
    if (cookies.get('nonce')!==null && cookies.get('nonce')!=='' && (nonce === '' || nonce == null)){
      nonce = cookies.get('nonce');
    }
    if (cookies.get('vault')!==null && cookies.get('vault')!=='' && (nonce === '' || nonce == null)){
      vault = cookies.get('vault');
    }
    console.log('Cookies:', msgSig, tokenName, evmToAddress, toTokenAddress, toNetId, fromNetId, toNetRadio, fromNetRadio, nonce, vault);
    return true;
  }
  catch (error) {
    dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Transaction Failure. Cookies not available. This DApp requires cookies to complete transaction.</h4>";
    dv.style.display= 'block';
    console.log('Transaction Failure...');
    return false;
  }
}

document.addEventListener("DOMContentLoaded", function(event) {
  console.log('Loaded');
  if (cookies.get("msgSig") && cookies.get("msgSig") !==''){
    console.log('Unprocessed transaction detected...');
    dv = document.getElementById("output");
    dv.innerHTML = "<h4 style={{backgroundColor: '#2B2F36'}}>Unprocessed transaction detected. Click \"Retry\"</h4>";
    dv5 = document.getElementById("retry");
    dv.style.display= 'block';
    dv5.style.display= 'block';
  }
});


fromNetRadio = "Select";
toNetRadio = "Select";

function Teleport({btn}) {
    const [valueFrom, setValueFrom] = useState('Select');
    const [valueTo, setValueTo] = useState('Select');
    const [fromIcn, setFromIcn] = useState(<FormDown color='white'/>);
    const [toIcn, setToIcn] = useState(<FormDown color='white'/>);
    getProvider();
    loc = useLocation();
    console.log('valueTo:', valueTo, 'valueFrom:', valueFrom);

    return (
    <Grommet theme={customBreakpoints}>
    <ResponsiveContext.Consumer>
        {responsive => (responsive === 'smallmob') ? (
            <Box background="#fff">
                <Card width="full" round="none" background="#fff" pad="75px 20px 100px">
                    <CardBody>
                            <Box background="#fff" justify="center" alignSelf="center">
                                <HeadingDark textAlign="center" margin={{ bottom: "35px", top: "0", horizontal: "0" }} size="4xl" weight="bold" color="#222323" level="2">Teleport Bridge</HeadingDark>
                                <StyledTextDark textAlign="center">Send coins privately between chains. The bridge fee is .75%. You must hold the native coins of the chains you wish to bridge.</StyledTextDark>
                            </Box>
                            <Box background="#fff" justify="center" alignSelf="center" pad="50px 0 0">


                                <Box background="#f9f9f9" pad={{ top: "40px", bottom: "50px", horizontal: "20px" }} round="23px" justify="center" alignSelf="center" style={formWrapStyleMob}>
                                <Box pad={{ top: "40px", bottom: "50px", horizontal: "10px" }} justify="center" alignSelf="center">{btn}</Box>

                                <Form name="bridgeTeleport" id="bridgeTeleport" onSubmit={handleInput}>
                                <Heading style={{ fontWeight: "normal"}} color="#222323" level="3" size="20px" margin={{ bottom: "35px", top: "0" }}  textAlign="center">Bridge Your Assets: </Heading>
                                <Box justify="center" alignSelf="center" >
                                    <Box justify="center" alignSelf="center"  width="medium" pad="small" background="white" round="xsmall">
                                      <Text justify="center" alignSelf="left" weight="bold">From:</Text>
                                      <Menu plain closed
                                        items={[
                                          {
                                            label: <Box alignSelf="center">{chainB}</Box>,
                                            value: chainB,
                                            onClick: () => {console.log(chainB); setFromIcn(<TokenB/>); handleValueFromDrop(chainB, setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenB size="large" />
                                                </Box>
                                              ),
                                            },
                                            {
                                            label: <Box alignSelf="center">{chainA}</Box>,
                                            onClick: () => {console.log(chainA); setFromIcn(<TokenA/>); handleValueFromDrop('SHM', setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenA size="large" />
                                                </Box>
                                              ),
                                            },
                                        ]}
                                        >
                                        <Box direction="row" gap="small" alignSelf="center" pad="small">
                                            <ButtonForm size='large' pad='medium' icon={fromIcn} style={{borderRadius: '10px'}} color='#F0B90C' margin={{top: "15px", horizontal: "auto"}} label={valueFrom} id='slctFromBtn'/>
                                        </Box>
                                      </Menu>

                                    </Box>


                                    <FormField
                                      name="Amt"
                                      alignSelf="center"
                                      pad="small"
                                      required
                                      width="medium"
                                      contentProps={{ border: false, margin: "0", pad: {top:"small", bottom:"0", left:"0", right:"0"} }}
                                      validate={[
                                        { regexp: /^\s*(?=.*[1-9])\d*(?:\.\d*)?\s*$/}
                                      ]}
                                    >
                                        <TextInput
                                          style={{background: 'white', color: '#222323', fontSize: "20px", fontWeight: "normal", borderRadius: "6px", height: "50px" }}
                                          name="Amt"
                                          placeholder={<Text weight="normal" size="20px" color="#707070">0.0
                                          </Text>}
                                          onChange={event =>{initialAmt=event.target.value; checkBalanceInput(initialAmt)}}
                                        />
                                    </FormField>

                                    <Box justify="center" alignSelf="center" pad="small" width="medium" background="white" round="xsmall">
                                      <Text justify="center" alignSelf="left" weight="bold">To:</Text>



                                      <Menu plain closed
                                        items={[
                                          {
                                            label: <Box alignSelf="center">{chainB}</Box>,
                                            value: chainB,
                                            onClick: () => {console.log(chainB); setToIcn(<TokenB/>); handleValueToDrop(chainB, setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenB size="large" />
                                                </Box>
                                              ),
                                            },
                                            {
                                            label: <Box alignSelf="center">{chainA}</Box>,
                                            onClick: () => {console.log(chainA); setToIcn(<TokenA/>); handleValueToDrop('SHM', setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenA size="large" />
                                                </Box>
                                              ),
                                            },
                                        ]}
                                        >

                                        <Box direction="row" gap="small" pad="small">
                                            <ButtonForm size='large' style={{borderRadius: '10px'}} pad='medium' margin={{top: "15px", horizontal: "auto"}} icon={toIcn} color='#F0B90C' label={valueTo} id='slctToBtn'/>
                                        </Box>
                                      </Menu>

                                    </Box>

                                    <FormField
                                      name="targetAddress"
                                      alignSelf="center"
                                      required
                                      width="medium"
                                      contentProps={{ border: false, margin: "0", pad: {top:"small", bottom:"0", left:"0", right:"0"} }}
                                      validate={[
                                        { regexp: /^0x[a-fA-F0-9]{40}$/}
                                      ]}
                                    >
                                        <TextInput
                                          style={{background: 'white', color: '#222323', fontSize: "20px", fontWeight: "normal", borderRadius: "6px", height: "50px" }}
                                          name="targetAddress"
                                          placeholder={<Text weight="normal" size="20px" color="#707070">Enter destination address
                                          </Text>}
                                          onChange={event =>{evmToAddress=event.target.value; tokenName="WSHM";}}
                                        />
                                    </FormField>

                                    <ButtonForm width="medium" style={{width: '50%' }} hoverIndicator={{background: "#222323", boxShadow: "0"}} margin={{top: "15px", horizontal: "auto"}} size='medium' color="#fff" type="submit" label="Submit"></ButtonForm>
                                </Box>
                                </Form>
                                <div style={{paddingTop: '2%'}}>

                                    <Box hidden id="outputCard" width="100%" responsive round="small" style={{backgroundColor:'#2B2F36', color:'white', padding:'0%'}}>
                                        <div hidden align="center" id="output" style={{padding:'2%'}}>
                                        </div>
                                        <div id="spin" align="center" hidden pad="medium" style={{padding:'2%'}}><Spinner size="large" /></div>
                                        <div hidden align="center" id="addToken" pad="medium" style={{padding:'2%'}}>
                                            <StyledButton size='large' pad="medium" color='#F0B90C' label='Add Token To Wallet' id='addToken' onClick={() => addCoin()}/>
                                        </div>
                                    </Box>
                                </div>
                                </Box>
                            </Box>
                    </CardBody>
                </Card>
            </Box>
        ) : (responsive === 'small') ? (
            <Box background="#fff">
                <Card width="full" round="none" background="#fff" pad="75px 20px 100px">
                    <CardBody>
                            <Box background="#fff" justify="center" alignSelf="center">
                                <HeadingDark textAlign="center" margin={{ bottom: "35px", top: "0", horizontal: "0" }} size="4xl" weight="bold" color="#222323" level="2">Teleport Bridge </HeadingDark>
                                <StyledTextDark textAlign="center">Send coins privately between chains. The bridge fee is .75%. You must hold the native coins of the chains you wish to bridge.</StyledTextDark>
                            </Box>
                            <Box background="#fff" justify="center" alignSelf="center" pad="50px 0 0">
                                <Box background="#f9f9f9" pad={{ top: "40px", bottom: "50px", horizontal: "20px" }} round="23px" justify="center" alignSelf="center" style={formWrapStyleMob}>
                                <Box pad={{ top: "40px", bottom: "50px", horizontal: "10px" }} justify="center" alignSelf="center">{btn}</Box>

                                <Form name="bridgeTeleport" id="bridgeTeleport" onSubmit={handleInput}>
                                <Heading style={{ fontWeight: "normal"}} color="#222323" level="3" size="20px" margin={{ bottom: "35px", top: "0" }}  textAlign="center">Bridge Your Assets: </Heading>
                                <Box justify="center" alignSelf="center" >
                                    <Box justify="center" alignSelf="center"  width="medium" pad="small" background="white" round="xsmall">
                                      <Text justify="center" alignSelf="left" weight="bold">From:</Text>

                                      <Menu plain closed
                                        items={[
                                          {
                                            label: <Box alignSelf="center">{chainB}</Box>,
                                            value: chainB,
                                            onClick: () => {console.log(chainB); setFromIcn(<TokenB/>); handleValueFromDrop(chainB, setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenB size="large" />
                                                </Box>
                                              ),
                                            },
                                            {
                                            label: <Box alignSelf="center">{chainA}</Box>,
                                            onClick: () => {console.log(chainA); setFromIcn(<TokenA/>); handleValueFromDrop('SHM', setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenA size="large" />
                                                </Box>
                                              ),
                                            },
                                        ]}
                                        >
                                        <Box direction="row" gap="small" alignSelf="center" pad="small">
                                            <ButtonForm size='large' pad='medium' icon={fromIcn} style={{borderRadius: '10px'}} color='#F0B90C' margin={{top: "15px", horizontal: "auto"}} label={valueFrom} id='slctFromBtn'/>
                                        </Box>
                                      </Menu>
                                    </Box>


                                    <FormField
                                      name="Amt"
                                      alignSelf="center"
                                      pad="small"
                                      required
                                      width="medium"
                                      contentProps={{ border: false, margin: "0", pad: {top:"small", bottom:"0", left:"0", right:"0"} }}
                                      validate={[
                                        { regexp: /^\s*(?=.*[1-9])\d*(?:\.\d*)?\s*$/}
                                      ]}
                                    >
                                        <TextInput
                                          style={{background: 'white', color: '#222323', fontSize: "20px", fontWeight: "normal", borderRadius: "6px", height: "50px" }}
                                          name="Amt"
                                          placeholder={<Text weight="normal" size="20px" color="#707070">0.0
                                          </Text>}
                                          onChange={event =>{initialAmt=event.target.value; checkBalanceInput(initialAmt)}}
                                        />
                                    </FormField>

                                    <Box justify="center" alignSelf="center" pad="small" width="medium" background="white" round="xsmall">
                                      <Text justify="center" alignSelf="left" weight="bold">To:</Text>


                                      <Menu plain closed
                                        items={[
                                          {
                                            label: <Box alignSelf="center">{chainB}</Box>,
                                            value: chainB,
                                            onClick: () => {console.log(chainB); setToIcn(<TokenB/>); handleValueToDrop(chainB, setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenB size="large" />
                                                </Box>
                                              ),
                                            },
                                            {
                                            label: <Box alignSelf="center">{chainA}</Box>,
                                            onClick: () => {console.log(chainA); setToIcn(<TokenA/>); handleValueToDrop('SHM', setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenA size="large" />
                                                </Box>
                                              ),
                                            },
                                        ]}
                                        >

                                        <Box direction="row" gap="small" pad="small">
                                            <ButtonForm size='large' style={{borderRadius: '10px'}} pad='medium' margin={{top: "15px", horizontal: "auto"}} icon={toIcn} color='#F0B90C' label={valueTo} id='slctToBtn'/>
                                        </Box>
                                      </Menu>

                                    </Box>

                                    <FormField
                                      name="targetAddress"
                                      alignSelf="center"
                                      required
                                      width="medium"
                                      contentProps={{ border: false, margin: "0", pad: {top:"small", bottom:"0", left:"0", right:"0"} }}
                                      validate={[
                                        { regexp: /^0x[a-fA-F0-9]{40}$/}
                                      ]}
                                    >
                                        <TextInput
                                          style={{background: 'white', color: '#222323', fontSize: "20px", fontWeight: "normal", borderRadius: "6px", height: "50px" }}
                                          name="targetAddress"
                                          placeholder={<Text weight="normal" size="20px" color="#707070">Enter destination address
                                          </Text>}
                                          onChange={event =>{evmToAddress=event.target.value; tokenName="WSHM";}}
                                        />
                                    </FormField>

                                    <ButtonForm width="medium" style={{width: '50%' }} hoverIndicator={{background: "#222323", boxShadow: "0"}} margin={{top: "15px", horizontal: "auto"}} size='medium' color="#fff" type="submit" label="Submit"></ButtonForm>
                                </Box>
                                </Form>

                                <div style={{paddingTop: '2%'}}>

                                    <Box hidden id="outputCard" width="100%" responsive round="small" style={{backgroundColor:'#2B2F36', color:'white', padding:'0%'}}>
                                        <div hidden align="center" id="output" style={{padding:'2%'}}>
                                        </div>
                                        <div id="spin" align="center" hidden pad="medium" style={{padding:'2%'}}><Spinner size="large" /></div>
                                        <div hidden align="center" id="addToken" pad="medium" style={{padding:'2%'}}>
                                            <StyledButton size='large' pad="medium" color='#F0B90C' label='Add Token To Wallet' id='addToken' onClick={() => addCoin()}/>
                                        </div>
                                    </Box>

                                </div>
                                </Box>
                            </Box>
                    </CardBody>
                </Card>
            </Box>
        ) : (responsive === 'tablet') ? (
            <Box background="#fff">
                <Card width="full" round="none" background="#fff" pad="75px 30px 100px">
                    <CardBody>

                            <Box background="#fff" justify="center" alignSelf="center">
                                <HeadingDark textAlign="center" margin={{ bottom: "35px", top: "0", horizontal: "0" }} size="4xl" weight="bold" color="#222323" level="2">Teleport Bridge </HeadingDark>
                                <StyledTextDark textAlign="left">Send coins privately between chains. The bridge fee is .75%. You must hold the native coins of the chains you wish to bridge.</StyledTextDark>

                            </Box>

                            <div style={{paddingTop: '2%'}}>

                            <Box background="#fff" justify="center" alignSelf="center" pad="50px 0 0">

                                <Box background="#f9f9f9" round="25px" justify="center" alignSelf="center" pad={{ top: "40px", bottom: "50px", horizontal: "20px" }} style={formWrapStyleMob}>

                                <Form name="bridgeTeleport" id="bridgeTeleport" onSubmit={handleInput}>
                                <Heading style={{ fontWeight: "normal"}} color="#222323" level="3" size="20px" margin={{ bottom: "35px", top: "0" }}  textAlign="center">Bridge Your Assets: </Heading>
                                <Box justify="center" alignSelf="center" >
                                    <Box pad={{ top: "40px", bottom: "50px", horizontal: "10px" }} justify="center" alignSelf="center">{btn}</Box>

                                    <Box justify="center" alignSelf="center"  width="medium" pad="small" background="white" round="xsmall">
                                      <Text justify="center" alignSelf="left" weight="bold">From:</Text>


                                      <Menu plain closed
                                        items={[
                                          {
                                            label: <Box alignSelf="center">{chainB}</Box>,
                                            value: chainB,
                                            onClick: () => {console.log(chainB); setFromIcn(<TokenB/>); handleValueFromDrop(chainB, setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenB size="large" />
                                                </Box>
                                              ),
                                            },
                                            {
                                            label: <Box alignSelf="center">{chainA}</Box>,
                                            onClick: () => {console.log(chainA); setFromIcn(<TokenA/>); handleValueFromDrop('SHM', setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenA size="large" />
                                                </Box>
                                              ),
                                            },
                                        ]}
                                        >
                                        <Box direction="row" gap="small" alignSelf="center" pad="small">
                                            <ButtonForm size='large' pad='medium' icon={fromIcn} style={{borderRadius: '10px'}} color='#F0B90C' margin={{top: "15px", horizontal: "auto"}} label={valueFrom} id='slctFromBtn'/>
                                        </Box>
                                      </Menu>

                                    </Box>


                                    <FormField
                                      name="Amt"
                                      alignSelf="center"
                                      pad="small"
                                      required
                                      width="medium"
                                      contentProps={{ border: false, margin: "0", pad: {top:"small", bottom:"0", left:"0", right:"0"} }}
                                      validate={[
                                        { regexp: /^\s*(?=.*[1-9])\d*(?:\.\d*)?\s*$/}
                                      ]}
                                    >
                                        <TextInput
                                          style={{background: 'white', color: '#222323', fontSize: "20px", fontWeight: "normal", borderRadius: "6px", height: "50px" }}
                                          name="Amt"
                                          placeholder={<Text weight="normal" size="20px" color="#707070">0.0
                                          </Text>}
                                          onChange={event =>{initialAmt=event.target.value; checkBalanceInput(initialAmt)}}
                                        />
                                    </FormField>

                                    <Box justify="center" alignSelf="center" pad="small" width="medium" background="white" round="xsmall">
                                      <Text justify="center" alignSelf="left" weight="bold">To:</Text>


                                      <Menu plain closed
                                        items={[
                                          {
                                            label: <Box alignSelf="center">chainB</Box>,
                                            value: chainB,
                                            onClick: () => {console.log(chainB); setToIcn(<TokenB/>); handleValueToDrop(chainB, setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenB size="large" />
                                                </Box>
                                              ),
                                            },
                                            {
                                            label: <Box alignSelf="center">{chainA}</Box>,
                                            onClick: () => {console.log(chainA); setToIcn(<TokenA/>); handleValueToDrop('SHM', setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenA size="large" />
                                                </Box>
                                              ),
                                            },
                                        ]}
                                        >

                                        <Box direction="row" gap="small" pad="small">
                                            <ButtonForm size='large' style={{borderRadius: '10px'}} pad='medium' margin={{top: "15px", horizontal: "auto"}} icon={toIcn} color='#F0B90C' label={valueTo} id='slctToBtn'/>
                                        </Box>
                                      </Menu>
                                    </Box>

                                    <FormField
                                      name="targetAddress"
                                      alignSelf="center"
                                      required
                                      width="medium"
                                      contentProps={{ border: false, margin: "0", pad: {top:"small", bottom:"0", left:"0", right:"0"} }}
                                      validate={[
                                        { regexp: /^0x[a-fA-F0-9]{40}$/}
                                      ]}
                                    >
                                        <TextInput
                                          style={{background: 'white', color: '#222323', fontSize: "20px", fontWeight: "normal", borderRadius: "6px", height: "50px" }}
                                          name="targetAddress"
                                          placeholder={<Text weight="normal" size="20px" color="#707070">Enter destination address
                                          </Text>}
                                          onChange={event =>{evmToAddress=event.target.value; tokenName="WSHM";}}
                                        />
                                    </FormField>

                                    <ButtonForm width="medium" style={{width: '50%' }} hoverIndicator={{background: "#222323", boxShadow: "0"}} margin={{top: "15px", horizontal: "auto"}} size='medium' color="#fff" type="submit" label="Submit"></ButtonForm>
                                </Box>
                                </Form>

                                <div style={{paddingTop: '2%'}}>
                                    <Box hidden id="outputCard" width="100%" responsive round="small" style={{backgroundColor:'#2B2F36', color:'white', padding:'0%'}}>
                                        <div hidden align="center" id="output" style={{padding:'2%', wordBreak: "break-word"}}>
                                        </div>
                                        <div id="spin" align="center" hidden pad="medium" style={{padding:'2%', wordBreak: "break-word"}}><Spinner size="large" /></div>

                                          <div hidden align="center" id="continue" pad="medium" style={{padding:'2%'}}>
                                          <StyledButton size='large' pad="medium" color='#F0B90C' label='Continue' id='continue' onClick={() => completeTransaction()}/>
                                          </div>

                                          <div hidden align="center" id="retry" pad="medium" style={{padding:'2%'}}>
                                          <StyledButton size='large' pad="medium" color='#F0B90C' label='Retry' id='retry' onClick={() => handleMint(cookies.get("amount"),cookies.get("cnt"), cookies.get("fromNetId"), cookies.get("toNetId"), cookies.get("tx"), cookies.get("nonce"), cookies.get("vault"))}/>
                                          </div>
                                        <div hidden align="center" id="addToken" pad="medium" style={{padding:'2%', wordBreak: "break-word"}}>
                                            <StyledButton size='large' pad="medium" color='#F0B90C' label='Add Token To Wallet' id='addToken' onClick={() => addCoin()}/>
                                        </div>
                                    </Box>
                                </div>
                                </Box>
                            </Box>
                            </div>

                    </CardBody>
                </Card>
            </Box>
        ) : (responsive === 'medium') ? (
            <Box background="#fff">
                <Card width="full" round="none" background="#fff" pad="50px 50px">
                    <CardBody>

                        <Grid fill areas={[ { name: 'left', start: [0, 0], end: [0, 0] }, { name: 'center', start: [1, 0], end: [1, 0]}, { name: 'right', start: [2, 0], end: [2, 0] }]}
                            columns={['1/4', '1/2' , '1/4']} alignContent="center" justifyContent="between" rows={['flex']} gap="none"
                            background="#fff">


                            <Box gridArea="center" background="#fff" justify="end" alignSelf="center" pad="0">
                                <HeadingDark textAlign="start" alignSelf="center" margin={{ bottom: "15px", top: "0", horizontal: "0" }} size="4xl" weight="bold" color="#222323" level="2">Teleport Bridge </HeadingDark>
                                <StyledTextDark textAlign="center" margin={{ horizontal: "large", vertical: "small"}}>Send coins privately between chains. The bridge fee is .75%. You must hold the native coins of the chains you wish to bridge.</StyledTextDark>
                                <Box background="#f9f9f9" pad={{ vertical: "medium", horizontal: "large" }} margin="medium" round="25px" justify="center" alignSelf="center" style={formWrapStyleMed}>
                                <Box pad={{ vertical: "xsmall", horizontal: "large" }} round="25px" justify="end" alignSelf="center">{btn}</Box>

                                <Form name="bridgeTeleport" id="bridgeTeleport" onSubmit={handleInput}>
                                <Box justify="center" alignSelf="center" width="100%">
                                    <Box justify="center" alignSelf="center"  width="medium" pad="small" background="white" round="xsmall">
                                      <Text justify="center" alignSelf="left" weight="bold">From:</Text>

                                      <Menu plain closed
                                        items={[
                                          {
                                            label: <Box alignSelf="center">{chainB}</Box>,
                                            value: chainB, //'Ethereum',
                                            onClick: () => {console.log(chainB); setFromIcn(<TokenB/>); handleValueFromDrop(chainB, setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenB size="large" />
                                                </Box>
                                              ),
                                            },
                                            {
                                            label: <Box alignSelf="center">{chainA}</Box>,
                                            onClick: () => {console.log(chainA); setFromIcn(<TokenA/>); handleValueFromDrop('SHM', setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenA size="large" />
                                                </Box>
                                              ),
                                            },
                                        ]}
                                        >
                                        <Box direction="row" gap="small" alignSelf="center" pad="small">
                                            <ButtonForm size='large' pad='medium' icon={fromIcn} style={{borderRadius: '10px'}} color='#F0B90C' margin={{top: "15px", horizontal: "auto"}} label={valueFrom} id='slctFromBtn'/>
                                        </Box>
                                      </Menu>


                                    </Box>


                                    <FormField
                                      name="Amt"
                                      alignSelf="center"
                                      pad="small"
                                      required
                                      width="medium"
                                      contentProps={{ border: false, margin: "0", pad: {top:"small", bottom:"0", left:"0", right:"0"} }}
                                      validate={[
                                        { regexp: /^\s*(?=.*[1-9])\d*(?:\.\d*)?\s*$/}
                                      ]}
                                    >
                                        <TextInput
                                          style={{background: 'white', color: '#222323', fontSize: "20px", fontWeight: "normal", borderRadius: "6px", height: "50px" }}
                                          name="Amt"
                                          placeholder={<Text weight="normal" size="20px" color="#707070">0.0
                                          </Text>}
                                          onChange={event =>{initialAmt=event.target.value; checkBalanceInput(initialAmt)}}
                                        />
                                    </FormField>

                                    <Box justify="center" alignSelf="center" pad="small" width="medium" background="white" round="xsmall">
                                      <Text justify="center" alignSelf="left" weight="bold">To:</Text>



                                      <Menu plain closed
                                        items={[
                                          {
                                            label: <Box alignSelf="center">{chainB}</Box>,
                                            value: chainB,
                                            onClick: () => {console.log(chainB); setToIcn(<TokenB/>); handleValueToDrop(chainB, setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenB size="large" />
                                                </Box>
                                              ),
                                            },
                                            {
                                            label: <Box alignSelf="center">{chainA}</Box>,
                                            onClick: () => {console.log(chainA); setToIcn(<TokenA/>); handleValueToDrop('SHM', setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenA size="large" />
                                                </Box>
                                              ),
                                            },
                                        ]}
                                        >

                                        <Box direction="row" gap="small" pad="small">
                                            <ButtonForm size='large' style={{borderRadius: '10px'}} pad='medium' margin={{top: "15px", horizontal: "auto"}} icon={toIcn} color='#F0B90C' label={valueTo} id='slctToBtn'/>
                                        </Box>
                                      </Menu>
                                    </Box>

                                    <FormField
                                      name="targetAddress"
                                      alignSelf="center"
                                      required
                                      width="medium"
                                      contentProps={{ border: false, margin: "0", pad: {top:"small", bottom:"0", left:"0", right:"0"} }}
                                      validate={[
                                        { regexp: /^0x[a-fA-F0-9]{40}$/}
                                      ]}
                                    >
                                        <TextInput
                                          style={{background: 'white', color: '#222323', fontSize: "20px", fontWeight: "normal", borderRadius: "6px", height: "50px" }}
                                          name="targetAddress"
                                          placeholder={<Text weight="normal" size="20px" color="#707070">Enter destination address
                                          </Text>}
                                          onChange={event =>{evmToAddress=event.target.value; tokenName="WSHM";}}
                                        />
                                    </FormField>


                                    <ButtonForm style={{width: '65%' }} hoverIndicator={{background: "#222323", boxShadow: "0"}} margin={{top: "15px", horizontal: "auto"}} size='large' color="#fff" type="submit" label="Submit"></ButtonForm>


                                </Box>
                                </Form>

                                <div style={{paddingTop: '2%'}}>
                                    <Box hidden id="outputCard" width="100%" responsive round="small" style={{backgroundColor:'#2B2F36', color:'white', padding:'0%'}}>
                                        <div hidden align="center" id="output" style={{padding:'2%', wordBreak: "break-word"}}>
                                        </div>
                                        <div id="spin" align="center" hidden pad="medium" style={{padding:'2%', wordBreak: "break-word"}}><Spinner size="large" /></div>

                                          <div hidden align="center" id="continue" pad="medium" style={{padding:'2%'}}>
                                          <StyledButton size='large' pad="medium" color='#F0B90C' label='Continue' id='continue' onClick={() => completeTransaction()}/>
                                          </div>

                                          <div hidden align="center" id="retry" pad="medium" style={{padding:'2%'}}>
                                          <StyledButton size='large' pad="medium" color='#F0B90C' label='Retry' id='retry' onClick={() => handleMint(cookies.get("amount"),cookies.get("cnt"), cookies.get("fromNetId"), cookies.get("toNetId"), cookies.get("tx"), cookies.get("nonce"), cookies.get("vault"))}/>
                                          </div>

                                        <div hidden align="center" id="addToken" pad="medium" style={{padding:'2%', wordBreak: "break-word"}}>
                                            <StyledButton size='large' pad="medium" color='#F0B90C' label='Add Token To Wallet' id='addToken' onClick={() => addCoin()}/>
                                        </div>
                                    </Box>
                                </div>
                                </Box>
                            </Box>
                        </Grid>
                    </CardBody>
                </Card>
            </Box>
        ) : (
            <Box background="#fff">
                <Card width="full" round="none" background="#fff" pad="0 8rem" size="large">
                    <CardBody>
                        <Grid fill areas={[ { name: 'left', start: [0, 0], end: [0, 0] }, { name: 'center', start: [1, 0], end: [1, 0]}, { name: 'right', start: [2, 0], end: [2, 0] }]}
                            columns={['1/4', '1/2' , '1/4']} alignContent="center" justifyContent="between" rows={['flex']} gap="none"
                            background="#fff">

                            <Box gridArea="center" background="#fff" justify="end" alignSelf="center" pad="0">
                              <HeadingDark textAlign="start" alignSelf="center" margin={{ bottom: "15px", top: "0", horizontal: "0" }} size="4xl" weight="bold" color="#222323" level="2">Teleport Bridge </HeadingDark>
                              <StyledTextDark textAlign="center" margin={{ horizontal: "large", vertical: "small"}}>Send coins privately between chains. The bridge fee is .75%. You must hold the native coins of the chains you wish to bridge.</StyledTextDark>

                              <Box background="#f9f9f9" pad={{ vertical: "medium", horizontal: "large" }} margin="medium" round="25px" justify="end" alignSelf="center" style={formWrapStyleMed}>
                              <Box pad={{ vertical: "xsmall", horizontal: "large" }} round="25px" justify="end" alignSelf="center">{btn}</Box>
                                <Form name="bridgeTeleport" id="bridgeTeleport" onSubmit={handleInput}>
                                <Heading style={{ fontWeight: "normal"}} color="#222323" level="3" size="20px" margin={{ bottom: "35px", top: "0" }}  textAlign="center">Bridge Your Assets: </Heading>

                                <Box justify="center" alignSelf="center" width="100%">
                                    <Box justify="center" alignSelf="center"  width="medium" pad="small" background="white" round="xsmall">
                                      <Text justify="center" alignSelf="left" weight="bold">From:</Text>

                                      <Menu plain closed
                                        items={[
                                          {
                                            label: <Box alignSelf="center">{chainB}</Box>,
                                            value: chainB,
                                            onClick: () => {console.log(chainB); setFromIcn(<TokenB/>); handleValueFromDrop(chainB, setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenB size="large" />
                                                </Box>
                                              ),
                                            },
                                            {
                                            label: <Box alignSelf="center">{chainA}</Box>,
                                            onClick: () => {console.log(chainA); setFromIcn(<TokenA/>); handleValueFromDrop('SHM', setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenA size="large" />
                                                </Box>
                                              ),
                                            },
                                        ]}
                                        >
                                        <Box direction="row" gap="small" alignSelf="center" pad="small">
                                            <ButtonForm size='large' pad='medium' icon={fromIcn} style={{borderRadius: '10px'}} color='#F0B90C' margin={{top: "15px", horizontal: "auto"}} label={valueFrom} id='slctFromBtn'/>
                                        </Box>
                                      </Menu>

                                    </Box>


                                    <FormField
                                      name="Amt"
                                      alignSelf="center"
                                      required
                                      width="medium"
                                      contentProps={{ border: false, margin: "0", pad: {top:"small", bottom:"0", left:"0", right:"0"} }}
                                      validate={[
                                        { regexp: /^\s*(?=.*[1-9])\d*(?:\.\d*)?\s*$/}
                                      ]}
                                    >
                                        <TextInput
                                          style={{background: 'white', color: '#222323', fontSize: "20px", fontWeight: "normal", borderRadius: "6px", height: "50px" }}
                                          name="Amt"
                                          placeholder={<Text weight="normal" size="20px" color="#707070">0.0
                                          </Text>}
                                          onChange={event =>{initialAmt=event.target.value;console.log("initialAmt",initialAmt); checkBalanceInput(initialAmt)}}
                                        />
                                    </FormField>

                                    <Box justify="center" alignSelf="center" pad="small" width="medium" background="white" round="xsmall">
                                      <Text justify="center" alignSelf="left" weight="bold">To:</Text>

                                      <Menu plain closed
                                        items={[
                                          {
                                            label: <Box alignSelf="center">{chainB}</Box>,
                                            value: chainB,
                                            onClick: () => {console.log(chainB); setToIcn(<TokenB/>); handleValueToDrop(chainB, setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenB size="large" />
                                                </Box>
                                              ),
                                            },
                                            {
                                            label: <Box alignSelf="center">{chainA}</Box>,
                                            onClick: () => {console.log(chainA); setToIcn(<TokenA/>); handleValueToDrop('SHM', setValueFrom, setValueTo)
                                            },
                                              icon: (
                                                <Box pad="medium">
                                                  <TokenA size="large" />
                                                </Box>
                                              ),
                                            },
                                        ]}
                                        >

                                        <Box direction="row" gap="small" pad="small">
                                            <ButtonForm size='large' style={{borderRadius: '10px'}} pad='medium' margin={{top: "15px", horizontal: "auto"}} icon={toIcn} color='#F0B90C' label={valueTo} id='slctToBtn'/>
                                        </Box>
                                      </Menu>


                                    </Box>

                                    <FormField
                                      name="targetAddress"
                                      alignSelf="center"
                                      required
                                      width="medium"
                                      contentProps={{ border: false, margin: "0", pad: {top:"small", bottom:"0", left:"0", right:"0"} }}
                                      validate={[
                                        { regexp: /^0x[a-fA-F0-9]{40}$/}
                                      ]}
                                    >
                                        <TextInput
                                          style={{background: 'white', color: '#222323', fontSize: "20px", fontWeight: "normal", borderRadius: "6px", height: "50px" }}
                                          name="targetAddress"
                                          placeholder={<Text weight="normal" size="20px" color="#707070">Enter destination address
                                          </Text>}
                                          onChange={event =>{evmToAddress=event.target.value;tokenName="WSHM";}}
                                        />
                                    </FormField>

                                    <ButtonForm style={{width: '55%' }} hoverIndicator={{background: "#222323", boxShadow: "0"}} margin={{top: "15px", horizontal: "auto"}} color="#fff" type="submit" label="Submit"></ButtonForm>
                                </Box>
                                </Form>

                                <div style={{paddingTop: '2%'}}>
                                    <Box hidden id="outputCard" width="100%" responsive round="small" style={{backgroundColor:'#2B2F36', color:'white', padding:'0%'}}>
                                        <div hidden align="center" id="output" style={{padding:'2%', wordBreak: "break-word"}}>
                                        </div>
                                        <div id="spin" align="center" hidden pad="medium" style={{padding:'2%', wordBreak: "break-word"}}><Spinner size="large" /></div>

                                          <div hidden align="center" id="continue" pad="medium" style={{padding:'2%'}}>
                                          <StyledButton size='large' pad="medium" color='#F0B90C' label='Continue' id='continue' onClick={() => completeTransaction()}/>
                                          </div>

                                          <div hidden align="center" id="retry" pad="medium" style={{padding:'2%'}}>
                                          <StyledButton size='large' pad="medium" color='#F0B90C' label='Retry' id='retry' onClick={() => handleMint(cookies.get("amount"),cookies.get("cnt"), cookies.get("fromNetId"), cookies.get("toNetId"), cookies.get("tx"), cookies.get("nonce"), cookies.get("vault"))}/>
                                          </div>

                                        <div hidden align="center" id="addToken" pad="medium" style={{padding:'2%', wordBreak: "break-word"}}>
                                            <StyledButton size='large' pad="medium" color='#F0B90C' label='Add Token To Wallet' id='addToken' onClick={() => addCoin()}/>
                                        </div>
                                    </Box>
                                </div>
                              </Box>
                            </Box>
                        </Grid>
                    </CardBody>
                </Card>
            </Box>
        )}
    </ResponsiveContext.Consumer>
    </Grommet>
  );

}

export default Teleport;
